import Swiper, { Navigation, Mousewheel, Keyboard } from "swiper"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "prev", "next"]

  connect() {
    console.log(this.getActiveSlideIndex())
    this.sliderContainer = this.hasContainerTarget
      ? this.containerTarget
      : this.element
    this.swiper = new Swiper(this.sliderContainer, {
      modules: [Navigation, Mousewheel, Keyboard],
      spaceBetween: 0,
      slidesPerView: "auto",
      loop: false,
      centeredSlides: false,
      simulateTouch: true,
      effect: "slide",
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      },
      keyboard: {
        enabled: true
      },
      mousewheel: {
        forceToAxis: true,
        releaseOnEdges: true
      },
      initialSlide: this.getActiveSlideIndex()
    })
  }

  getActiveSlideIndex() {
    let slide = this.element.querySelector("[data-active-slide]")
    if (!slide) return 0
    return Array.from(slide.parentNode.children).indexOf(slide)
  }

  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
}
