import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect"
const german = require("flatpickr/dist/l10n/de.js").default.de

export default class extends Controller {
  static targets = []
  static values = { currentDate: String, url: String }
  datepicker

  connect() {
    const defaultDate = this.hasCurrentDateValue
      ? new Date(this.currentDateValue)
      : null
    this.datepicker = flatpickr(this.element, {
      locale: german,
      defaultDate: defaultDate,
      disableMobile: true,
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: "F Y", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
          theme: "light" // defaults to "light"
        })
      ],
      onChange: this.fireChangeEvent.bind(this)
    })
  }

  fireChangeEvent(selectedDates, dateStr, instance) {
    const event = new CustomEvent("monthpicker:date:changed", {
      detail: selectedDates[0],
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  submit(e) {
    const url = new URL(this.urlValue)
    date = this._format_date_YYYY_MM_DD(e.detail)
    url.searchParams.set("date", date)
    window.location = url.toString()

    // fetch(url.toString())
    //$.get(url.toString()) somehow doesnt trigger ajax:success
  }

  _format_date_YYYY_MM_DD(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  disconnect() {
    this.datepicker.destroy()
    this.datepicker = null
  }
}
