import { Controller } from "stimulus"
import Player from "@vimeo/player"

export default class extends Controller {
  static targets = ["player", "button"]

  connect() {
    const player = new Player(this.playerTarget)
    const button = this.buttonTarget

    // console.log("connect");

    // player.on("play", function () {
    //   button.classList.remove("disabled");
    //   button.removeAttribute("disabled");
    // });

    player.on("ended", function () {
      button.classList.remove("disabled")
      button.removeAttribute("disabled")
    })
  }

  // disconnect() {
  // }
}
